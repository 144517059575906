import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { storage } from '../../utils/storage';
import { v4 as uuidv4 } from 'uuid';
import { Users2, Plus, Award, UserPlus, X, UserMinus, Trophy } from 'lucide-react';
import { Group } from '../../types';

export const GroupManagement = () => {
  const { user, selectedClass } = useAuth();
  const [showCreateGroup, setShowCreateGroup] = useState(false);
  const [newGroupName, setNewGroupName] = useState('');
  const [newGroupDescription, setNewGroupDescription] = useState('');
  const [selectedStudents, setSelectedStudents] = useState<string[]>([]);

  if (!selectedClass) {
    return (
      <div className="text-center py-12 bg-white rounded-lg shadow-sm">
        <Users2 className="mx-auto h-12 w-12 text-gray-400" />
        <h3 className="mt-2 text-sm font-medium text-gray-900">Aucune classe sélectionnée</h3>
        <p className="mt-1 text-sm text-gray-500">
          Veuillez sélectionner une classe pour gérer les groupes
        </p>
      </div>
    );
  }

  const groups = storage.getGroups().filter(g => g.teacherId === user?.id && g.classId === selectedClass);
  const students = storage.getUsers().filter(u => u.role === 'student' && u.class === selectedClass);

  const handleCreateGroup = (e: React.FormEvent) => {
    e.preventDefault();
    if (!user || !selectedClass) return;

    const newGroup: Group = {
      id: uuidv4(),
      name: newGroupName,
      description: newGroupDescription,
      teacherId: user.id,
      classId: selectedClass,
      studentIds: selectedStudents,
      createdAt: new Date().toISOString(),
      assignments: []
    };

    storage.saveGroup(newGroup);

    // Notify selected students
    selectedStudents.forEach(studentId => {
      const notification = {
        id: uuidv4(),
        userId: studentId,
        title: 'Ajout à un groupe',
        message: `Vous avez été ajouté au groupe "${newGroupName}". Membres du groupe: ${
          selectedStudents
            .map(id => students.find(s => s.id === id)?.name)
            .filter(Boolean)
            .join(', ')
        }`,
        type: 'info' as const,
        createdAt: new Date().toISOString(),
        read: false,
        groupId: newGroup.id
      };
      storage.saveNotification(notification);
    });

    setNewGroupName('');
    setNewGroupDescription('');
    setSelectedStudents([]);
    setShowCreateGroup(false);
  };

  const handleRemoveStudentFromGroup = (group: Group, studentId: string) => {
    if (window.confirm('Êtes-vous sûr de vouloir retirer cet étudiant du groupe ?')) {
      const updatedGroup = {
        ...group,
        studentIds: group.studentIds.filter(id => id !== studentId)
      };

      storage.updateGroup(updatedGroup);

      // Notify student about removal
      const notification = {
        id: uuidv4(),
        userId: studentId,
        title: 'Retrait du groupe',
        message: `Vous avez été retiré du groupe "${group.name}"`,
        type: 'info' as const,
        createdAt: new Date().toISOString(),
        read: false,
        groupId: group.id
      };
      storage.saveNotification(notification);

      // Notify remaining members
      updatedGroup.studentIds.forEach(memberId => {
        const notification = {
          id: uuidv4(),
          userId: memberId,
          title: 'Modification du groupe',
          message: `${students.find(s => s.id === studentId)?.name} a été retiré du groupe "${group.name}"`,
          type: 'info' as const,
          createdAt: new Date().toISOString(),
          read: false,
          groupId: group.id
        };
        storage.saveNotification(notification);
      });
    }
  };

  const handleAddStudentToGroup = (group: Group, studentId: string) => {
    const updatedGroup = {
      ...group,
      studentIds: [...group.studentIds, studentId]
    };

    storage.updateGroup(updatedGroup);

    // Notify new student
    const notification = {
      id: uuidv4(),
      userId: studentId,
      title: 'Ajout à un groupe',
      message: `Vous avez été ajouté au groupe "${group.name}". Membres du groupe: ${
        updatedGroup.studentIds
          .map(id => students.find(s => s.id === id)?.name)
          .filter(Boolean)
          .join(', ')
      }`,
      type: 'info' as const,
      createdAt: new Date().toISOString(),
      read: false,
      groupId: group.id
    };
    storage.saveNotification(notification);

    // Notify existing members
    group.studentIds.forEach(memberId => {
      const notification = {
        id: uuidv4(),
        userId: memberId,
        title: 'Nouveau membre dans le groupe',
        message: `${students.find(s => s.id === studentId)?.name} a rejoint le groupe "${group.name}"`,
        type: 'info' as const,
        createdAt: new Date().toISOString(),
        read: false,
        groupId: group.id
      };
      storage.saveNotification(notification);
    });
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-medium flex items-center">
          <Users2 className="w-5 h-5 mr-2" />
          Gestion des groupes
        </h2>
        <button
          onClick={() => setShowCreateGroup(true)}
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
        >
          <Plus className="w-4 h-4 mr-2" />
          Nouveau groupe
        </button>
      </div>

      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {groups.map((group) => {
          const groupStudents = students.filter(s => group.studentIds.includes(s.id));
          const availableStudents = students.filter(s => !group.studentIds.includes(s.id));

          return (
            <div key={group.id} className="bg-white rounded-lg shadow-sm p-6">
              <div className="flex items-center justify-between">
                <h3 className="text-lg font-medium">{group.name}</h3>
              </div>

              {group.description && (
                <p className="mt-1 text-sm text-gray-500">{group.description}</p>
              )}

              <div className="mt-4">
                <div className="flex justify-between items-center mb-2">
                  <h4 className="text-sm font-medium text-gray-700">
                    Membres ({groupStudents.length})
                  </h4>
                  {availableStudents.length > 0 && (
                    <div className="relative">
                      <select
                        onChange={(e) => {
                          if (e.target.value) {
                            handleAddStudentToGroup(group, e.target.value);
                            e.target.value = '';
                          }
                        }}
                        className="text-sm text-gray-500 border border-gray-300 rounded-md"
                      >
                        <option value="">Ajouter un membre</option>
                        {availableStudents.map(student => (
                          <option key={student.id} value={student.id}>
                            {student.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                </div>
                <ul className="mt-2 space-y-2">
                  {groupStudents.map(student => (
                    <li key={student.id} className="flex items-center justify-between text-sm bg-gray-50 p-2 rounded">
                      <div className="flex items-center">
                        <UserPlus className="w-4 h-4 text-gray-400 mr-2" />
                        {student.name}
                      </div>
                      <button
                        onClick={() => handleRemoveStudentFromGroup(group, student.id)}
                        className="text-red-500 hover:text-red-700"
                        title="Retirer du groupe"
                      >
                        <UserMinus className="w-4 h-4" />
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          );
        })}
      </div>

      {showCreateGroup && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center">
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-medium">Nouveau groupe</h2>
              <button
                onClick={() => setShowCreateGroup(false)}
                className="text-gray-400 hover:text-gray-500"
              >
                <X className="w-5 h-5" />
              </button>
            </div>

            <form onSubmit={handleCreateGroup} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Nom du groupe
                </label>
                <input
                  type="text"
                  value={newGroupName}
                  onChange={(e) => setNewGroupName(e.target.value)}
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Description
                </label>
                <textarea
                  value={newGroupDescription}
                  onChange={(e) => setNewGroupDescription(e.target.value)}
                  rows={3}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Membres
                </label>
                <select
                  multiple
                  value={selectedStudents}
                  onChange={(e) => {
                    const values = Array.from(e.target.selectedOptions, option => option.value);
                    setSelectedStudents(values);
                  }}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                >
                  {students.map(student => (
                    <option key={student.id} value={student.id}>
                      {student.name}
                    </option>
                  ))}
                </select>
                <p className="mt-1 text-sm text-gray-500">
                  Maintenez Ctrl (Cmd sur Mac) pour sélectionner plusieurs étudiants
                </p>
              </div>

              <div className="flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => setShowCreateGroup(false)}
                  className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                  Annuler
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                >
                  Créer
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};