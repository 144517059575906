import React, { useState } from 'react';
import { storage } from '../../utils/storage';
import { useAuth } from '../../contexts/AuthContext';
import { Plus, Edit2, Trash2, BookOpen, GraduationCap } from 'lucide-react';
import { v4 as uuidv4 } from 'uuid';
import { Class, Subject } from '../../types';

export const ClassManagement = () => {
  const { user } = useAuth();
  const [showAddClass, setShowAddClass] = useState(false);
  const [showAddSubject, setShowAddSubject] = useState(false);
  const [selectedClass, setSelectedClass] = useState<Class | null>(null);
  const [newClassName, setNewClassName] = useState('');
  const [newClassDescription, setNewClassDescription] = useState('');
  const [newSubjectName, setNewSubjectName] = useState('');
  const [newSubjectDescription, setNewSubjectDescription] = useState('');

  const classes = storage.getClasses().filter(c => c.teacherId === user?.id) || [];

  const handleAddClass = (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) return;

    const newClass: Class = {
      id: uuidv4(),
      name: newClassName,
      description: newClassDescription,
      teacherId: user.id,
      createdAt: new Date().toISOString(),
      subjects: []
    };

    storage.saveClass(newClass);
    setNewClassName('');
    setNewClassDescription('');
    setShowAddClass(false);
  };

  const handleAddSubject = (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedClass) return;

    const newSubject: Subject = {
      id: uuidv4(),
      name: newSubjectName,
      description: newSubjectDescription
    };

    const updatedClass = {
      ...selectedClass,
      subjects: [...(selectedClass.subjects || []), newSubject]
    };

    storage.updateClass(updatedClass);
    setNewSubjectName('');
    setNewSubjectDescription('');
    setShowAddSubject(false);
  };

  const handleDeleteClass = (classId: string) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cette classe ?')) {
      storage.updateClass({
        ...classes.find(c => c.id === classId)!,
        deleted: true
      });
    }
  };

  const handleDeleteSubject = (classId: string, subjectId: string) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cette matière ?')) {
      const classToUpdate = classes.find(c => c.id === classId);
      if (!classToUpdate) return;

      const updatedClass = {
        ...classToUpdate,
        subjects: (classToUpdate.subjects || []).filter(s => s.id !== subjectId)
      };

      storage.updateClass(updatedClass);
    }
  };

  if (!user) return null;

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-medium flex items-center">
          <GraduationCap className="w-5 h-5 mr-2" />
          Gestion des classes et matières
        </h2>
        <button
          onClick={() => setShowAddClass(true)}
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
        >
          <Plus className="w-4 h-4 mr-2" />
          Nouvelle classe
        </button>
      </div>

      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {classes.map(classItem => (
          <div key={classItem.id} className="bg-white rounded-lg shadow p-6">
            <div className="flex justify-between items-start mb-4">
              <div>
                <h3 className="text-lg font-medium">{classItem.name}</h3>
                {classItem.description && (
                  <p className="text-sm text-gray-500 mt-1">{classItem.description}</p>
                )}
              </div>
              <button
                onClick={() => handleDeleteClass(classItem.id)}
                className="text-red-600 hover:text-red-800"
              >
                <Trash2 className="w-4 h-4" />
              </button>
            </div>

            <div className="space-y-4">
              <div className="flex justify-between items-center">
                <h4 className="text-sm font-medium text-gray-700 flex items-center">
                  <BookOpen className="w-4 h-4 mr-2" />
                  Matières
                </h4>
                <button
                  onClick={() => {
                    setSelectedClass(classItem);
                    setShowAddSubject(true);
                  }}
                  className="text-indigo-600 hover:text-indigo-800"
                >
                  <Plus className="w-4 h-4" />
                </button>
              </div>

              <ul className="space-y-2">
                {(classItem.subjects || []).map(subject => (
                  <li key={subject.id} className="flex justify-between items-center bg-gray-50 p-2 rounded">
                    <span className="text-sm">{subject.name}</span>
                    <button
                      onClick={() => handleDeleteSubject(classItem.id, subject.id)}
                      className="text-red-600 hover:text-red-800"
                    >
                      <Trash2 className="w-4 h-4" />
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>

      {/* Modal pour ajouter une classe */}
      {showAddClass && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center">
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <h3 className="text-lg font-medium mb-4">Nouvelle classe</h3>
            <form onSubmit={handleAddClass} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Nom de la classe
                </label>
                <input
                  type="text"
                  value={newClassName}
                  onChange={(e) => setNewClassName(e.target.value)}
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Description
                </label>
                <textarea
                  value={newClassDescription}
                  onChange={(e) => setNewClassDescription(e.target.value)}
                  rows={3}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              </div>
              <div className="flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => setShowAddClass(false)}
                  className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                  Annuler
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                >
                  Créer
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Modal pour ajouter une matière */}
      {showAddSubject && selectedClass && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center">
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <h3 className="text-lg font-medium mb-4">
              Nouvelle matière pour {selectedClass.name}
            </h3>
            <form onSubmit={handleAddSubject} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Nom de la matière
                </label>
                <input
                  type="text"
                  value={newSubjectName}
                  onChange={(e) => setNewSubjectName(e.target.value)}
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Description
                </label>
                <textarea
                  value={newSubjectDescription}
                  onChange={(e) => setNewSubjectDescription(e.target.value)}
                  rows={3}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              </div>
              <div className="flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => setShowAddSubject(false)}
                  className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                  Annuler
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                >
                  Ajouter
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};