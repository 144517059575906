import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { storage } from '../../utils/storage';
import { v4 as uuidv4 } from 'uuid';
import { Send, Users } from 'lucide-react';

interface ChatMessage {
  id: string;
  senderId: string;
  classId: string;
  content: string;
  timestamp: string;
}

const CHAT_MESSAGES_KEY = 'class_chat_messages';

export const ClassChat: React.FC = () => {
  const { user } = useAuth();
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (!user?.class) return;

    // Load initial messages
    const storedMessages = localStorage.getItem(CHAT_MESSAGES_KEY);
    const parsedMessages = storedMessages 
      ? JSON.parse(storedMessages).filter((m: ChatMessage) => m.classId === user.class)
      : [];
    setMessages(parsedMessages);

    // Set up interval to check for new messages
    const interval = setInterval(() => {
      const currentMessages = localStorage.getItem(CHAT_MESSAGES_KEY);
      const currentParsedMessages = currentMessages 
        ? JSON.parse(currentMessages).filter((m: ChatMessage) => m.classId === user.class)
        : [];
      setMessages(currentParsedMessages);
    }, 1000);

    return () => clearInterval(interval);
  }, [user?.class]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSendMessage = (e: React.FormEvent) => {
    e.preventDefault();
    if (!message.trim() || !user?.class) return;

    const newMessage: ChatMessage = {
      id: uuidv4(),
      senderId: user.id,
      classId: user.class,
      content: message.trim(),
      timestamp: new Date().toISOString()
    };

    // Get existing messages
    const existingMessages = localStorage.getItem(CHAT_MESSAGES_KEY);
    const parsedMessages = existingMessages ? JSON.parse(existingMessages) : [];
    
    // Add new message
    const updatedMessages = [...parsedMessages, newMessage];
    localStorage.setItem(CHAT_MESSAGES_KEY, JSON.stringify(updatedMessages));

    // Update state
    setMessages(updatedMessages.filter(m => m.classId === user.class));
    setMessage('');
  };

  const formatTime = (timestamp: string) => {
    return new Date(timestamp).toLocaleTimeString([], { 
      hour: '2-digit', 
      minute: '2-digit' 
    });
  };

  if (!user?.class) {
    return null;
  }

  const classInfo = storage.getClasses().find(c => c.id === user.class);
  const students = storage.getUsers().filter(u => u.role === 'student' && u.class === user.class);

  return (
    <div className="bg-white rounded-lg shadow-md">
      <div className="border-b p-4 flex items-center justify-between">
        <div className="flex items-center">
          <Users className="w-5 h-5 text-gray-500 mr-2" />
          <h3 className="text-lg font-medium">Chat de classe - {classInfo?.name}</h3>
        </div>
        <span className="text-sm text-gray-500">
          {students.length} étudiant{students.length > 1 ? 's' : ''} connecté{students.length > 1 ? 's' : ''}
        </span>
      </div>

      <div className="h-[400px] overflow-y-auto p-4 space-y-4">
        {messages.map(msg => {
          const sender = storage.getUsers().find(u => u.id === msg.senderId);
          const isOwnMessage = msg.senderId === user.id;

          return (
            <div
              key={msg.id}
              className={`flex ${isOwnMessage ? 'justify-end' : 'justify-start'}`}
            >
              <div
                className={`max-w-[70%] px-4 py-2 rounded-lg ${
                  isOwnMessage
                    ? 'bg-indigo-600 text-white'
                    : 'bg-gray-100 text-gray-900'
                }`}
              >
                {!isOwnMessage && (
                  <p className={`text-xs font-medium mb-1 ${
                    isOwnMessage ? 'text-indigo-100' : 'text-gray-600'
                  }`}>
                    {sender?.name}
                  </p>
                )}
                <p className="text-sm break-words">{msg.content}</p>
                <p className={`text-xs mt-1 ${
                  isOwnMessage ? 'text-indigo-100' : 'text-gray-500'
                }`}>
                  {formatTime(msg.timestamp)}
                </p>
              </div>
            </div>
          );
        })}
        <div ref={messagesEndRef} />
      </div>

      <form onSubmit={handleSendMessage} className="border-t p-4">
        <div className="flex space-x-2">
          <input
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Écrivez votre message..."
            className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          />
          <button
            type="submit"
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
          >
            <Send className="w-4 h-4" />
          </button>
        </div>
      </form>
    </div>
  );
};