import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { storage } from '../../utils/storage';
import { generateMatricule } from '../../utils/generateMatricule';
import { X } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';

interface CreateStudentProps {
  onClose: () => void;
}

export const CreateStudent: React.FC<CreateStudentProps> = ({ onClose }) => {
  const [name, setName] = useState('');
  const { selectedClass } = useAuth();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!selectedClass) {
      alert('Veuillez sélectionner une classe avant de créer un étudiant');
      return;
    }

    const matricule = generateMatricule(name);
    const newStudent = {
      id: uuidv4(),
      name,
      matricule,
      password: 'PASSER',
      role: 'student' as const,
      class: selectedClass
    };

    storage.saveUser(newStudent);
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center">
      <div className="bg-white rounded-lg p-6 max-w-md w-full">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-medium">Nouvel étudiant</h2>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
            <X className="w-5 h-5" />
          </button>
        </div>
        
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
              Nom complet
            </label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              placeholder="Entrez le nom complet de l'étudiant"
            />
            <p className="mt-1 text-sm text-gray-500">
              Le matricule sera généré automatiquement à partir du nom
            </p>
          </div>

          <div className="bg-blue-50 p-4 rounded-md">
            <p className="text-sm text-blue-700">
              Le mot de passe par défaut est : <strong>PASSER</strong>
            </p>
            <p className="text-xs text-blue-600 mt-1">
              L'étudiant pourra le modifier lors de sa première connexion
            </p>
          </div>

          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              Annuler
            </button>
            <button
              type="submit"
              className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
            >
              Créer
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};