import React, { useState } from 'react';
import { Assignment, Submission } from '../../types';
import { storage } from '../../utils/storage';
import { useAuth } from '../../contexts/AuthContext';
import { FileText, Calendar, CheckCircle, XCircle, Clock, Download } from 'lucide-react';
import { AssignmentSubmission } from './AssignmentSubmission';

interface AssignmentCardProps {
  assignment: Assignment;
  submission: Submission | null;
}

export const AssignmentCard: React.FC<AssignmentCardProps> = ({ assignment, submission }) => {
  const [showSubmitForm, setShowSubmitForm] = useState(false);
  const { user } = useAuth();

  const isOverdue = new Date(assignment.dueDate) < new Date();
  const timeLeft = new Date(assignment.dueDate).getTime() - new Date().getTime();
  const daysLeft = Math.ceil(timeLeft / (1000 * 60 * 60 * 24));

  const handleDownload = (fileId: string) => {
    const file = storage.getFiles().find(f => f.id === fileId);
    if (!file) return;

    const link = document.createElement('a');
    link.href = `data:application/octet-stream;base64,${file.content}`;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="bg-white overflow-hidden shadow rounded-lg">
      <div className="p-5">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <FileText className="w-5 h-5 text-gray-400" />
            <h3 className="ml-2 text-lg font-medium text-gray-900">
              {assignment.title}
            </h3>
          </div>
          <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
            assignment.type === 'homework' 
              ? 'bg-blue-100 text-blue-800' 
              : 'bg-purple-100 text-purple-800'
          }`}>
            {assignment.type === 'homework' ? 'Devoir' : 'TP'}
          </span>
        </div>

        <div className="mt-2">
          <p className="text-sm text-gray-500">{assignment.description}</p>
        </div>

        <div className="mt-4 flex items-center justify-between">
          <div className="flex items-center">
            <Calendar className="w-5 h-5 text-gray-400" />
            <p className="ml-2 text-sm text-gray-500">
              Date limite: {new Date(assignment.dueDate).toLocaleDateString()}
            </p>
          </div>
          {!isOverdue && !submission && (
            <div className="flex items-center text-amber-600">
              <Clock className="w-5 h-5" />
              <p className="ml-2 text-sm">
                {daysLeft} jour{daysLeft > 1 ? 's' : ''} restant{daysLeft > 1 ? 's' : ''}
              </p>
            </div>
          )}
        </div>

        {submission ? (
          <div className="mt-4 bg-gray-50 p-4 rounded-md">
            <div className="flex items-center">
              <CheckCircle className="w-5 h-5 text-green-500" />
              <p className="ml-2 text-sm text-gray-700">
                Soumis le {new Date(submission.submittedAt).toLocaleDateString()}
              </p>
            </div>
            {submission.grade !== undefined && (
              <div className="mt-2">
                <p className="text-sm font-medium text-gray-900">
                  Note: {submission.grade}/20
                </p>
                {submission.grade >= 10 ? (
                  <p className="text-sm text-green-600">Validé</p>
                ) : (
                  <p className="text-sm text-red-600">Non validé</p>
                )}
              </div>
            )}
            {submission.feedback && (
              <div className="mt-2 p-3 bg-white rounded border border-gray-200">
                <p className="text-sm font-medium text-gray-700">Feedback du professeur:</p>
                <p className="mt-1 text-sm text-gray-600">{submission.feedback}</p>
              </div>
            )}
            {submission.attachments?.length > 0 && (
              <div className="mt-3">
                <p className="text-sm font-medium text-gray-700 mb-2">Fichiers joints:</p>
                <div className="space-y-2">
                  {submission.attachments.map(fileId => {
                    const file = storage.getFiles().find(f => f.id === fileId);
                    return file && (
                      <button
                        key={file.id}
                        onClick={() => handleDownload(file.id)}
                        className="flex items-center text-sm text-indigo-600 hover:text-indigo-800"
                      >
                        <Download className="w-4 h-4 mr-1" />
                        {file.name}
                      </button>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        ) : (
          !isOverdue && (
            <div className="mt-4">
              {showSubmitForm ? (
                <AssignmentSubmission
                  assignment={assignment}
                  onSubmit={() => {
                    setShowSubmitForm(false);
                    window.location.reload();
                  }}
                  onCancel={() => setShowSubmitForm(false)}
                />
              ) : (
                <button
                  onClick={() => setShowSubmitForm(true)}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                >
                  Répondre
                </button>
              )}
            </div>
          )
        )}

        {isOverdue && !submission && (
          <div className="mt-4 flex items-center text-red-500">
            <XCircle className="w-5 h-5" />
            <p className="ml-2 text-sm">Date limite dépassée</p>
          </div>
        )}
      </div>
    </div>
  );
};