import React, { useState } from 'react';
import { storage } from '../../utils/storage';
import { useAuth } from '../../contexts/AuthContext';
import { FileText, Calendar, Eye } from 'lucide-react';
import { AssignmentDetails } from './AssignmentDetails';
import { Assignment } from '../../types';

export const AssignmentList = () => {
  const { selectedClass } = useAuth();
  const assignments = storage.getAssignments(selectedClass || undefined);
  const submissions = storage.getSubmissions();
  const [selectedAssignment, setSelectedAssignment] = useState<Assignment | null>(null);

  if (!selectedClass) {
    return (
      <div className="text-center py-12">
        <p className="text-gray-500">Veuillez sélectionner une classe pour voir les devoirs</p>
      </div>
    );
  }

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <ul className="divide-y divide-gray-200">
        {assignments.map(assignment => {
          const submissionCount = submissions.filter(
            s => s.assignmentId === assignment.id
          ).length;

          const ungraded = submissions.filter(
            s => s.assignmentId === assignment.id && s.grade === undefined
          ).length;

          return (
            <li key={assignment.id}>
              <div className="px-4 py-4 sm:px-6">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <FileText className="w-5 h-5 text-gray-400" />
                    <p className="ml-2 text-sm font-medium text-indigo-600">
                      {assignment.title}
                    </p>
                  </div>
                  <div className="flex items-center space-x-4">
                    <div className="flex items-center">
                      <Calendar className="w-5 h-5 text-gray-400" />
                      <p className="ml-2 text-sm text-gray-500">
                        Date limite: {new Date(assignment.dueDate).toLocaleDateString()}
                      </p>
                    </div>
                    <button
                      onClick={() => setSelectedAssignment(assignment)}
                      className="inline-flex items-center px-3 py-1 border border-transparent text-sm leading-5 font-medium rounded-md text-indigo-600 bg-indigo-100 hover:bg-indigo-200"
                    >
                      <Eye className="w-4 h-4 mr-1" />
                      Détails
                    </button>
                  </div>
                </div>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">{assignment.description}</p>
                </div>
                <div className="mt-2 flex space-x-4">
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                    {submissionCount} soumission(s)
                  </span>
                  {ungraded > 0 && (
                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                      {ungraded} à noter
                    </span>
                  )}
                </div>
              </div>
            </li>
          );
        })}
      </ul>

      {selectedAssignment && (
        <AssignmentDetails
          assignment={selectedAssignment}
          onClose={() => setSelectedAssignment(null)}
        />
      )}
    </div>
  );
};