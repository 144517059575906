import { storage } from './storage';

export const generateMatricule = (name: string): string => {
  // Remove accents and special characters
  const normalizedName = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  
  // Get only letters and convert to uppercase
  const cleanName = normalizedName.replace(/[^a-zA-Z]/g, '').toUpperCase();
  
  // Take first 3 letters of the name (or pad with 'X' if shorter)
  const namePrefix = (cleanName + 'XXX').slice(0, 3);
  
  // Get current year
  const year = new Date().getFullYear().toString().slice(-2);
  
  // Get existing matricules for this year
  const existingMatricules = storage.getUsers()
    .map(u => u.matricule)
    .filter(m => m.includes(year));
  
  // Find next available number
  let counter = 1;
  let matricule: string;
  
  do {
    matricule = `${namePrefix}${year}${counter.toString().padStart(3, '0')}`;
    counter++;
  } while (existingMatricules.includes(matricule));
  
  return matricule;
};