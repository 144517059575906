import React, { useState } from 'react';
import { AssignmentCard } from '../components/student/AssignmentCard';
import { ClassChat } from '../components/student/ClassChat';
import { StudentStats } from '../components/student/StudentStats';
import { GroupInfo } from '../components/student/GroupInfo';
import { storage } from '../utils/storage';
import { useAuth } from '../contexts/AuthContext';
import { Clock, CheckCircle, GraduationCap, BookOpen, BarChart2, Calendar, MessageSquare, BarChart } from 'lucide-react';

export const StudentDashboard = () => {
  const { user } = useAuth();
  const assignments = storage.getAssignments();
  const submissions = storage.getSubmissions();
  const [selectedFilter, setSelectedFilter] = useState<'all' | 'pending' | 'submitted'>('all');
  const [showChat, setShowChat] = useState(false);
  const [showStats, setShowStats] = useState(true);

  if (!user) return null;

  const userSubmissions = submissions.filter(s => s.studentId === user.id);
  const pendingAssignments = assignments.filter(assignment => {
    return !submissions.some(
      submission => 
        submission.assignmentId === assignment.id && 
        submission.studentId === user.id
    );
  });

  const submittedAssignments = assignments.filter(assignment => {
    return submissions.some(
      submission => 
        submission.assignmentId === assignment.id && 
        submission.studentId === user.id
    );
  });

  const stats = {
    totalAssignments: assignments.length,
    completedAssignments: submittedAssignments.length,
    averageGrade: userSubmissions
      .filter(s => s.grade !== undefined)
      .reduce((acc, s) => acc + (s.grade || 0), 0) / 
      userSubmissions.filter(s => s.grade !== undefined).length || 0,
    nextDeadline: pendingAssignments.length > 0 
      ? new Date(Math.min(...pendingAssignments.map(a => new Date(a.dueDate).getTime())))
      : null
  };

  const displayedAssignments = selectedFilter === 'all' 
    ? assignments
    : selectedFilter === 'pending' 
      ? pendingAssignments 
      : submittedAssignments;

  return (
    <div className="space-y-6">
      {/* Statistics Cards */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        <div className="bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200">
          <div className="flex items-center">
            <BookOpen className="w-10 h-10 text-indigo-500" />
            <div className="ml-4">
              <p className="text-sm font-medium text-gray-500">Total des devoirs</p>
              <p className="text-2xl font-bold text-gray-900">{stats.totalAssignments}</p>
            </div>
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200">
          <div className="flex items-center">
            <CheckCircle className="w-10 h-10 text-green-500" />
            <div className="ml-4">
              <p className="text-sm font-medium text-gray-500">Devoirs complétés</p>
              <p className="text-2xl font-bold text-gray-900">{stats.completedAssignments}</p>
            </div>
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200">
          <div className="flex items-center">
            <BarChart2 className="w-10 h-10 text-yellow-500" />
            <div className="ml-4">
              <p className="text-sm font-medium text-gray-500">Moyenne générale</p>
              <p className="text-2xl font-bold text-gray-900">
                {stats.averageGrade ? `${stats.averageGrade.toFixed(2)}/20` : 'N/A'}
              </p>
            </div>
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200">
          <div className="flex items-center">
            <Calendar className="w-10 h-10 text-red-500" />
            <div className="ml-4">
              <p className="text-sm font-medium text-gray-500">Prochaine échéance</p>
              <p className="text-2xl font-bold text-gray-900">
                {stats.nextDeadline 
                  ? stats.nextDeadline.toLocaleDateString()
                  : 'Aucune'}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Group Info Section */}
      <GroupInfo />

      {/* Toggle Buttons */}
      <div className="flex justify-end space-x-4">
        <button
          onClick={() => setShowStats(!showStats)}
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 transition-colors duration-200"
        >
          <BarChart className="w-4 h-4 mr-2" />
          {showStats ? 'Masquer les statistiques' : 'Afficher les statistiques'}
        </button>
        <button
          onClick={() => setShowChat(!showChat)}
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 transition-colors duration-200"
        >
          <MessageSquare className="w-4 h-4 mr-2" />
          {showChat ? 'Masquer le chat' : 'Afficher le chat'}
        </button>
      </div>

      {/* Statistics Section */}
      {showStats && (
        <div className="mb-6">
          <StudentStats 
            assignments={assignments}
            submissions={userSubmissions}
          />
        </div>
      )}

      {/* Chat Section */}
      {showChat && (
        <div className="mb-6">
          <ClassChat />
        </div>
      )}

      {/* Filters */}
      <div className="bg-white p-4 rounded-lg shadow-sm">
        <div className="flex items-center space-x-4">
          <button
            onClick={() => setSelectedFilter('all')}
            className={`px-4 py-2 rounded-md transition-colors duration-200 ${
              selectedFilter === 'all'
                ? 'bg-indigo-100 text-indigo-700'
                : 'text-gray-600 hover:bg-gray-100'
            }`}
          >
            Tous les devoirs
          </button>
          <button
            onClick={() => setSelectedFilter('pending')}
            className={`px-4 py-2 rounded-md transition-colors duration-200 ${
              selectedFilter === 'pending'
                ? 'bg-amber-100 text-amber-700'
                : 'text-gray-600 hover:bg-gray-100'
            }`}
          >
            À faire
          </button>
          <button
            onClick={() => setSelectedFilter('submitted')}
            className={`px-4 py-2 rounded-md transition-colors duration-200 ${
              selectedFilter === 'submitted'
                ? 'bg-green-100 text-green-700'
                : 'text-gray-600 hover:bg-gray-100'
            }`}
          >
            Soumis
          </button>
        </div>
      </div>

      {/* Assignments Grid */}
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
        {displayedAssignments.length > 0 ? (
          displayedAssignments.map(assignment => {
            const submission = submissions.find(
              s => s.assignmentId === assignment.id && s.studentId === user.id
            );
            return (
              <AssignmentCard 
                key={assignment.id} 
                assignment={assignment}
                submission={submission || null}
              />
            );
          })
        ) : (
          <div className="col-span-full text-center py-12 bg-white rounded-lg">
            <GraduationCap className="mx-auto h-12 w-12 text-gray-400" />
            <h3 className="mt-2 text-sm font-medium text-gray-900">Aucun devoir</h3>
            <p className="mt-1 text-sm text-gray-500">
              {selectedFilter === 'pending' 
                ? "Vous n'avez aucun devoir en attente."
                : selectedFilter === 'submitted'
                ? "Vous n'avez encore soumis aucun devoir."
                : "Aucun devoir n'est disponible pour le moment."}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};