import React, { useState } from 'react';
import { Assignment, Submission } from '../../types';
import { storage } from '../../utils/storage';
import { FileText, User, Star, MessageSquare, Download, Clock, Calendar } from 'lucide-react';
import { format } from 'date-fns';

interface AssignmentDetailsProps {
  assignment: Assignment;
  onClose: () => void;
}

export const AssignmentDetails: React.FC<AssignmentDetailsProps> = ({ assignment, onClose }) => {
  const submissions = storage.getSubmissions().filter(s => s.assignmentId === assignment.id);
  const students = storage.getUsers().filter(u => u.role === 'student');

  const [selectedSubmission, setSelectedSubmission] = useState<Submission | null>(null);
  const [grade, setGrade] = useState<string>('');
  const [feedback, setFeedback] = useState('');

  const handleGradeSubmit = (submissionId: string) => {
    const submission = submissions.find(s => s.id === submissionId);
    if (!submission) return;

    const gradeNumber = parseFloat(grade);
    if (isNaN(gradeNumber) || gradeNumber < 0 || gradeNumber > 20) {
      alert('Veuillez entrer une note valide entre 0 et 20');
      return;
    }

    const updatedSubmission = {
      ...submission,
      grade: gradeNumber,
      feedback,
      status: 'graded' as const
    };

    storage.updateSubmission(updatedSubmission);

    // Create a notification for the student
    const notification = {
      id: crypto.randomUUID(),
      userId: submission.studentId,
      title: `Note reçue pour ${assignment.title}`,
      message: `Vous avez reçu une note de ${gradeNumber}/20${feedback ? '. Commentaire: ' + feedback : ''}`,
      type: 'info' as const,
      createdAt: new Date().toISOString(),
      read: false
    };
    storage.saveNotification(notification);

    setSelectedSubmission(null);
    setGrade('');
    setFeedback('');
  };

  const handleDownload = (fileId: string) => {
    const file = storage.getFiles().find(f => f.id === fileId);
    if (!file) return;

    const link = document.createElement('a');
    link.href = `data:application/octet-stream;base64,${file.content}`;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center">
      <div className="bg-white rounded-lg p-6 max-w-4xl w-full max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-gray-900">{assignment.title}</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            ×
          </button>
        </div>

        <div className="space-y-6">
          <div className="bg-gray-50 p-4 rounded-lg">
            <h3 className="text-lg font-medium mb-2">Statistiques</h3>
            <div className="grid grid-cols-3 gap-4">
              <div className="bg-white p-4 rounded-md shadow">
                <p className="text-sm text-gray-500">Total soumissions</p>
                <p className="text-2xl font-bold">{submissions.length}</p>
              </div>
              <div className="bg-white p-4 rounded-md shadow">
                <p className="text-sm text-gray-500">Moyenne de la classe</p>
                <p className="text-2xl font-bold">
                  {submissions.filter(s => s.grade !== undefined).length > 0
                    ? (
                        submissions
                          .filter(s => s.grade !== undefined)
                          .reduce((acc, s) => acc + (s.grade || 0), 0) /
                        submissions.filter(s => s.grade !== undefined).length
                      ).toFixed(2)
                    : 'N/A'}
                </p>
              </div>
              <div className="bg-white p-4 rounded-md shadow">
                <p className="text-sm text-gray-500">À noter</p>
                <p className="text-2xl font-bold">
                  {submissions.filter(s => s.grade === undefined).length}
                </p>
              </div>
            </div>
          </div>

          <div className="space-y-4">
            <h3 className="text-lg font-medium">Soumissions</h3>
            {submissions.length === 0 ? (
              <p className="text-gray-500 text-center py-4">
                Aucune soumission pour le moment
              </p>
            ) : (
              submissions.map(submission => {
                const student = students.find(s => s.id === submission.studentId);
                const submissionDate = new Date(submission.submittedAt);
                const isLate = new Date(assignment.dueDate) < submissionDate;

                return (
                  <div
                    key={submission.id}
                    className="bg-white p-4 rounded-lg shadow border border-gray-200"
                  >
                    <div className="flex items-center justify-between mb-4">
                      <div className="flex items-center">
                        <User className="w-5 h-5 text-gray-400" />
                        <span className="ml-2 font-medium">{student?.name}</span>
                      </div>
                      <div className="flex items-center space-x-4">
                        <div className="flex items-center text-sm text-gray-500">
                          <Calendar className="w-4 h-4 mr-1" />
                          {format(submissionDate, 'dd/MM/yyyy')}
                        </div>
                        <div className="flex items-center text-sm text-gray-500">
                          <Clock className="w-4 h-4 mr-1" />
                          {format(submissionDate, 'HH:mm')}
                        </div>
                        {isLate && (
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                            En retard
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="prose max-w-none">
                      <p className="text-gray-600">{submission.content}</p>
                    </div>

                    {submission.attachments && submission.attachments.length > 0 && (
                      <div className="mt-4">
                        <h4 className="text-sm font-medium text-gray-700 mb-2">Fichiers joints:</h4>
                        <div className="space-y-2">
                          {submission.attachments.map(fileId => {
                            const file = storage.getFiles().find(f => f.id === fileId);
                            return file && (
                              <button
                                key={file.id}
                                onClick={() => handleDownload(file.id)}
                                className="flex items-center text-sm text-indigo-600 hover:text-indigo-800"
                              >
                                <Download className="w-4 h-4 mr-1" />
                                {file.name}
                              </button>
                            );
                          })}
                        </div>
                      </div>
                    )}

                    <div className="mt-4 flex items-center justify-between">
                      {submission.grade !== undefined ? (
                        <div className="flex items-center space-x-4">
                          <div className="flex items-center">
                            <Star className="w-5 h-5 text-yellow-400" />
                            <span className="ml-1 font-medium">{submission.grade}/20</span>
                          </div>
                          {submission.feedback && (
                            <div className="flex items-center text-gray-500">
                              <MessageSquare className="w-4 h-4 mr-1" />
                              <span className="text-sm">{submission.feedback}</span>
                            </div>
                          )}
                        </div>
                      ) : (
                        <button
                          onClick={() => setSelectedSubmission(submission)}
                          className="inline-flex items-center px-3 py-1 border border-transparent text-sm leading-5 font-medium rounded-md text-indigo-600 bg-indigo-100 hover:bg-indigo-200"
                        >
                          Noter
                        </button>
                      )}
                    </div>

                    {selectedSubmission?.id === submission.id && (
                      <div className="mt-4 space-y-4 border-t pt-4">
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Note (/20)
                          </label>
                          <input
                            type="number"
                            min="0"
                            max="20"
                            step="0.5"
                            value={grade}
                            onChange={(e) => setGrade(e.target.value)}
                            className="mt-1 block w-32 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Commentaire
                          </label>
                          <textarea
                            value={feedback}
                            onChange={(e) => setFeedback(e.target.value)}
                            rows={3}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                          />
                        </div>
                        <div className="flex justify-end space-x-3">
                          <button
                            onClick={() => setSelectedSubmission(null)}
                            className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
                          >
                            Annuler
                          </button>
                          <button
                            onClick={() => handleGradeSubmit(submission.id)}
                            className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                          >
                            Enregistrer la note
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
    </div>
  );
};