import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { storage } from '../utils/storage';
import { v4 as uuidv4 } from 'uuid';
import { MessageSquare, Send, Users, User, Paperclip, X, CheckCircle } from 'lucide-react';
import { FileUpload } from './FileUpload';

export const MessagingSystem = () => {
  const { user, selectedClass } = useAuth();
  const [message, setMessage] = useState('');
  const [selectedRecipient, setSelectedRecipient] = useState<'all' | string>('all');
  const [showMessageForm, setShowMessageForm] = useState(false);
  const [attachments, setAttachments] = useState<string[]>([]);
  const [showSuccess, setShowSuccess] = useState(false);

  if (!user) return null;

  const getTeacher = () => {
    if (user.role === 'student' && user.class) {
      const classData = storage.getClasses().find(c => c.id === user.class);
      if (classData) {
        return storage.getUsers().find(u => u.id === classData.teacherId);
      }
    }
    return null;
  };

  const getRecipients = () => {
    if (user.role === 'teacher') {
      return storage.getUsers().filter(u => 
        u.role === 'student' && u.class === selectedClass
      );
    } else {
      const teacher = getTeacher();
      return teacher ? [teacher] : [];
    }
  };

  const recipients = getRecipients();

  const handleFileUpload = (fileId: string) => {
    setAttachments(prev => [...prev, fileId]);
  };

  const handleRemoveAttachment = (fileId: string) => {
    setAttachments(prev => prev.filter(id => id !== fileId));
  };

  const handleSendMessage = (e: React.FormEvent) => {
    e.preventDefault();
    if (!message.trim() || !user?.class) return;

    let recipientIds: string[];
    if (user.role === 'teacher') {
      recipientIds = selectedRecipient === 'all' 
        ? recipients.map(s => s.id)
        : [selectedRecipient];
    } else {
      const teacher = getTeacher();
      if (!teacher) {
        console.error('No teacher found for this class');
        return;
      }
      recipientIds = [teacher.id];
    }

    // Create the message
    const newMessage = {
      id: uuidv4(),
      senderId: user.id,
      recipientIds,
      content: message.trim(),
      createdAt: new Date().toISOString(),
      attachments,
      isClassMessage: user.role === 'teacher' && selectedRecipient === 'all',
      classId: user.class
    };

    // Save the message
    storage.saveMessage(newMessage);

    // Create notifications for recipients
    recipientIds.forEach(recipientId => {
      const notification = {
        id: uuidv4(),
        userId: recipientId,
        title: `Nouveau message de ${user.name}`,
        message: message.trim(),
        type: 'info' as const,
        createdAt: new Date().toISOString(),
        read: false,
        attachments,
        messageId: newMessage.id
      };
      storage.saveNotification(notification);
    });

    // Show success message
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
      setShowMessageForm(false);
    }, 2000);

    setMessage('');
    setAttachments([]);
  };

  const files = attachments.map(id => storage.getFiles().find(f => f.id === id));

  return (
    <div className="mt-4">
      <button
        onClick={() => setShowMessageForm(true)}
        className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
      >
        <MessageSquare className="w-4 h-4 mr-2" />
        {user.role === 'teacher' ? 'Envoyer un message' : 'Message au professeur'}
      </button>

      {showMessageForm && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center">
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            {showSuccess ? (
              <div className="flex flex-col items-center justify-center py-8 animate-fade-in">
                <CheckCircle className="w-16 h-16 text-green-500 mb-4" />
                <h2 className="text-xl font-medium text-green-700">Message envoyé avec succès!</h2>
              </div>
            ) : (
              <>
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-lg font-medium">
                    {user.role === 'teacher' ? 'Nouveau message' : 'Message au professeur'}
                  </h2>
                  <button
                    onClick={() => setShowMessageForm(false)}
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <X className="w-5 h-5" />
                  </button>
                </div>

                <form onSubmit={handleSendMessage} className="space-y-4">
                  {user.role === 'teacher' && (
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Destinataire
                      </label>
                      <select
                        value={selectedRecipient}
                        onChange={(e) => setSelectedRecipient(e.target.value)}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      >
                        <option value="all">Toute la classe</option>
                        {recipients.map(student => (
                          <option key={student.id} value={student.id}>
                            {student.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}

                  {user.role === 'student' && (
                    <div className="bg-gray-50 p-4 rounded-md mb-4">
                      <div className="flex items-center">
                        <User className="w-5 h-5 text-gray-400 mr-2" />
                        <p className="text-sm text-gray-600">
                          À: {getTeacher()?.name || 'Professeur'}
                        </p>
                      </div>
                    </div>
                  )}

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Message
                    </label>
                    <textarea
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      rows={4}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      placeholder="Écrivez votre message ici..."
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Pièces jointes
                    </label>
                    <div className="space-y-2">
                      {files.map(file => file && (
                        <div key={file.id} className="flex items-center justify-between bg-gray-50 p-2 rounded">
                          <div className="flex items-center">
                            <Paperclip className="w-4 h-4 text-gray-400 mr-2" />
                            <span className="text-sm text-gray-600">{file.name}</span>
                          </div>
                          <button
                            type="button"
                            onClick={() => handleRemoveAttachment(file.id)}
                            className="text-red-500 hover:text-red-700"
                          >
                            <X className="w-4 h-4" />
                          </button>
                        </div>
                      ))}
                    </div>
                    <div className="mt-2">
                      <FileUpload onUpload={handleFileUpload} />
                    </div>
                  </div>

                  <div className="flex justify-end space-x-3">
                    <button
                      type="button"
                      onClick={() => setShowMessageForm(false)}
                      className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
                    >
                      Annuler
                    </button>
                    <button
                      type="submit"
                      className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                    >
                      <Send className="w-4 h-4 mr-2" />
                      Envoyer
                    </button>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};