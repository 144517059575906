import React, { useState } from 'react';
import { storage } from '../../utils/storage';
import { User } from '../../types';
import { UserCircle, Edit2, Lock, Ban, CheckCircle } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';

interface EditStudentModalProps {
  student: User;
  onClose: () => void;
  onSave: (updatedStudent: User) => void;
}

const EditStudentModal: React.FC<EditStudentModalProps> = ({ student, onClose, onSave }) => {
  const [name, setName] = useState(student.name);
  const [email, setEmail] = useState(student.email || '');
  const [selectedClass, setSelectedClass] = useState(student.class || '');
  const classes = storage.getClasses();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSave({
      ...student,
      name,
      email,
      class: selectedClass
    });
  };

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center">
      <div className="bg-white rounded-lg p-6 max-w-md w-full">
        <h3 className="text-lg font-medium mb-4">Modifier l'étudiant</h3>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Nom</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Classe</label>
            <select
              value={selectedClass}
              onChange={(e) => setSelectedClass(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            >
              <option value="">Sélectionner une classe</option>
              {classes.map(classItem => (
                <option key={classItem.id} value={classItem.id}>
                  {classItem.name}
                </option>
              ))}
            </select>
          </div>
          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              Annuler
            </button>
            <button
              type="submit"
              className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
            >
              Enregistrer
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export const StudentList = () => {
  const [editingStudent, setEditingStudent] = useState<User | null>(null);
  const { selectedClass } = useAuth();
  const users = storage.getUsers().filter(user => 
    user.role === 'student' && 
    (!selectedClass || user.class === selectedClass)
  );
  const classes = storage.getClasses();

  if (!selectedClass) {
    return (
      <div className="bg-white shadow rounded-lg p-6 text-center">
        <p className="text-gray-500">Veuillez sélectionner une classe pour voir la liste des étudiants</p>
      </div>
    );
  }

  const handleEditStudent = (updatedStudent: User) => {
    storage.updateUser(updatedStudent);
    setEditingStudent(null);
  };

  const handleResetPassword = (student: User) => {
    if (window.confirm(`Réinitialiser le mot de passe de ${student.name} ?`)) {
      const updatedStudent = {
        ...student,
        password: 'PASSER'
      };
      storage.updateUser(updatedStudent);
      alert('Mot de passe réinitialisé avec succès à "PASSER"');
    }
  };

  const handleToggleSuspension = (student: User) => {
    const action = student.suspended ? 'réactiver' : 'suspendre';
    if (window.confirm(`Voulez-vous ${action} le compte de ${student.name} ?`)) {
      const updatedStudent = {
        ...student,
        suspended: !student.suspended
      };
      storage.updateUser(updatedStudent);
    }
  };

  const getClassName = (classId: string | undefined) => {
    if (!classId) return 'Non assigné';
    const classItem = classes.find(c => c.id === classId);
    return classItem ? classItem.name : 'Non assigné';
  };

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      {users.length === 0 ? (
        <div className="p-6 text-center">
          <p className="text-gray-500">Aucun étudiant dans cette classe</p>
        </div>
      ) : (
        <ul className="divide-y divide-gray-200">
          {users.map((student: User) => (
            <li key={student.id}>
              <div className="px-4 py-4 flex items-center justify-between sm:px-6">
                <div className="flex items-center">
                  <UserCircle className="w-8 h-8 text-gray-400" />
                  <div className="ml-4">
                    <p className={`font-medium ${student.suspended ? 'text-gray-400' : 'text-indigo-600'}`}>
                      {student.name}
                    </p>
                    <p className="mt-1 text-sm text-gray-500">Matricule: {student.matricule}</p>
                    {student.email && (
                      <p className="text-sm text-gray-500">{student.email}</p>
                    )}
                    <p className="text-sm text-gray-500">
                      Classe: {getClassName(student.class)}
                    </p>
                    {student.suspended && (
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                        Compte suspendu
                      </span>
                    )}
                  </div>
                </div>
                <div className="flex items-center space-x-4">
                  <button
                    onClick={() => setEditingStudent(student)}
                    className="text-gray-400 hover:text-gray-500"
                    title="Modifier"
                  >
                    <Edit2 className="w-5 h-5" />
                  </button>
                  <button
                    onClick={() => handleResetPassword(student)}
                    className="text-yellow-400 hover:text-yellow-500"
                    title="Réinitialiser le mot de passe"
                  >
                    <Lock className="w-5 h-5" />
                  </button>
                  <button
                    onClick={() => handleToggleSuspension(student)}
                    className={`${
                      student.suspended 
                        ? 'text-green-400 hover:text-green-500' 
                        : 'text-red-400 hover:text-red-500'
                    }`}
                    title={student.suspended ? 'Réactiver le compte' : 'Suspendre le compte'}
                  >
                    {student.suspended ? (
                      <CheckCircle className="w-5 h-5" />
                    ) : (
                      <Ban className="w-5 h-5" />
                    )}
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}

      {editingStudent && (
        <EditStudentModal
          student={editingStudent}
          onClose={() => setEditingStudent(null)}
          onSave={handleEditStudent}
        />
      )}
    </div>
  );
};