import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LineChart, Line, PieChart, Pie, Cell } from 'recharts';
import { Assignment, Submission } from '../../types';
import { format } from 'date-fns';

interface StudentStatsProps {
  assignments: Assignment[];
  submissions: Submission[];
}

const COLORS = ['#10B981', '#3B82F6', '#6366F1', '#8B5CF6', '#EC4899'];

export const StudentStats: React.FC<StudentStatsProps> = ({ assignments, submissions }) => {
  // Prepare data for grade distribution chart
  const gradeData = submissions
    .filter(s => s.grade !== undefined)
    .map(s => ({
      assignment: assignments.find(a => a.id === s.assignmentId)?.title || 'Unknown',
      grade: s.grade || 0
    }));

  // Calculate submission status data
  const totalAssignments = assignments.length;
  const submittedCount = submissions.length;
  const pendingCount = totalAssignments - submittedCount;
  const gradedCount = submissions.filter(s => s.grade !== undefined).length;
  const notGradedCount = submittedCount - gradedCount;

  const statusData = [
    { name: 'Soumis et notés', value: gradedCount },
    { name: 'En attente de note', value: notGradedCount },
    { name: 'À faire', value: pendingCount }
  ];

  // Calculate progress over time
  const progressData = submissions
    .sort((a, b) => new Date(a.submittedAt).getTime() - new Date(b.submittedAt).getTime())
    .map((submission, index) => ({
      date: format(new Date(submission.submittedAt), 'dd/MM'),
      submissions: index + 1,
      grade: submission.grade || 0
    }));

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
      {/* Grades Chart */}
      <div className="bg-white p-4 rounded-lg shadow-sm">
        <h3 className="text-lg font-medium mb-4">Notes par devoir</h3>
        <div className="h-64">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={gradeData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="assignment" angle={-45} textAnchor="end" height={80} />
              <YAxis domain={[0, 20]} />
              <Tooltip />
              <Bar dataKey="grade" fill="#10B981" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Progress Chart */}
      <div className="bg-white p-4 rounded-lg shadow-sm">
        <h3 className="text-lg font-medium mb-4">Progression des soumissions</h3>
        <div className="h-64">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={progressData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Line type="monotone" dataKey="submissions" stroke="#3B82F6" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Status Distribution */}
      <div className="bg-white p-4 rounded-lg shadow-sm">
        <h3 className="text-lg font-medium mb-4">État des devoirs</h3>
        <div className="h-64">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={statusData}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={({ name, percent }) => `${name} (${(percent * 100).toFixed(0)}%)`}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
              >
                {statusData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Grade Distribution */}
      <div className="bg-white p-4 rounded-lg shadow-sm">
        <h3 className="text-lg font-medium mb-4">Distribution des notes</h3>
        <div className="space-y-2">
          {[...Array(4)].map((_, i) => {
            const min = i * 5;
            const max = (i + 1) * 5;
            const count = submissions.filter(
              s => s.grade !== undefined && s.grade >= min && s.grade < max
            ).length;
            const percentage = (count / submissions.length) * 100 || 0;

            return (
              <div key={i} className="relative">
                <div className="flex justify-between text-sm text-gray-600 mb-1">
                  <span>{`${min}-${max}/20`}</span>
                  <span>{count} devoir(s)</span>
                </div>
                <div className="w-full bg-gray-200 rounded-full h-2.5">
                  <div
                    className="bg-green-600 h-2.5 rounded-full"
                    style={{ width: `${percentage}%` }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};