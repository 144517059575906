import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { storage } from '../../utils/storage';
import { Users2, User } from 'lucide-react';

export const GroupInfo = () => {
  const { user } = useAuth();
  
  if (!user) return null;

  // Find user's group
  const userGroup = storage.getGroups().find(group => 
    group.studentIds.includes(user.id)
  );

  if (!userGroup) {
    return (
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="flex items-center text-gray-500">
          <Users2 className="w-5 h-5 mr-2" />
          <p>Vous n'êtes pas encore assigné à un groupe</p>
        </div>
      </div>
    );
  }

  // Get group members
  const groupMembers = storage.getUsers().filter(u => 
    userGroup.studentIds.includes(u.id)
  );

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-lg font-medium flex items-center">
          <Users2 className="w-5 h-5 mr-2 text-indigo-500" />
          Mon Groupe: {userGroup.name}
        </h3>
        <span className="text-sm text-gray-500">
          {groupMembers.length} membre{groupMembers.length > 1 ? 's' : ''}
        </span>
      </div>

      {userGroup.description && (
        <p className="text-sm text-gray-600 mb-4">{userGroup.description}</p>
      )}

      <div className="space-y-3">
        <h4 className="text-sm font-medium text-gray-700">Membres du groupe:</h4>
        <ul className="divide-y divide-gray-100">
          {groupMembers.map(member => (
            <li 
              key={member.id} 
              className="flex items-center py-2"
            >
              <User className="w-4 h-4 text-gray-400 mr-2" />
              <span className="text-sm">
                {member.name}
                {member.id === user.id && (
                  <span className="ml-2 text-xs text-indigo-600">(Vous)</span>
                )}
              </span>
            </li>
          ))}
        </ul>
      </div>

      {userGroup.averageGrade !== undefined && (
        <div className="mt-4 pt-4 border-t border-gray-100">
          <div className="flex items-center justify-between text-sm">
            <span className="text-gray-600">Moyenne du groupe:</span>
            <span className="font-medium text-indigo-600">
              {userGroup.averageGrade.toFixed(2)}/20
            </span>
          </div>
        </div>
      )}
    </div>
  );
};