import { User, Assignment, Submission, Class, Subject, Message, Notification } from '../types';

// Clés de stockage
const STORAGE_KEYS = {
  USERS: 'app_users',
  CLASSES: 'app_classes',
  SUBJECTS: 'app_subjects',
  ASSIGNMENTS: 'app_assignments',
  SUBMISSIONS: 'app_submissions',
  MESSAGES: 'app_messages',
  NOTIFICATIONS: 'app_notifications'
} as const;

// Type générique pour les opérations CRUD
type Entity = {
  id: string;
  [key: string]: any;
};

// Classe de base pour les opérations CRUD
class BaseStorage<T extends Entity> {
  constructor(private storageKey: string) {}

  private getAll(): T[] {
    const data = localStorage.getItem(this.storageKey);
    return data ? JSON.parse(data) : [];
  }

  private save(items: T[]): void {
    localStorage.setItem(this.storageKey, JSON.stringify(items));
  }

  create(item: T): T {
    const items = this.getAll();
    items.push(item);
    this.save(items);
    return item;
  }

  findById(id: string): T | null {
    const items = this.getAll();
    return items.find(item => item.id === id) || null;
  }

  findAll(): T[] {
    return this.getAll();
  }

  update(item: T): void {
    const items = this.getAll();
    const index = items.findIndex(i => i.id === item.id);
    if (index !== -1) {
      items[index] = item;
      this.save(items);
    }
  }

  delete(id: string): void {
    const items = this.getAll();
    const filtered = items.filter(item => item.id !== id);
    this.save(filtered);
  }
}

// Classes spécifiques pour chaque type d'entité
export class UserStorage extends BaseStorage<User> {
  constructor() {
    super(STORAGE_KEYS.USERS);
  }

  findByMatricule(matricule: string): User | null {
    return this.findAll().find(user => user.matricule === matricule) || null;
  }

  findByClass(classId: string): User[] {
    return this.findAll().filter(user => user.class === classId);
  }
}

export class ClassStorage extends BaseStorage<Class> {
  constructor() {
    super(STORAGE_KEYS.CLASSES);
  }

  findByTeacher(teacherId: string): Class[] {
    return this.findAll().filter(c => c.teacherId === teacherId);
  }
}

export class AssignmentStorage extends BaseStorage<Assignment> {
  constructor() {
    super(STORAGE_KEYS.ASSIGNMENTS);
  }

  findByClass(classId: string): Assignment[] {
    return this.findAll().filter(a => a.classId === classId);
  }
}

export class SubmissionStorage extends BaseStorage<Submission> {
  constructor() {
    super(STORAGE_KEYS.SUBMISSIONS);
  }

  findByAssignment(assignmentId: string): Submission[] {
    return this.findAll().filter(s => s.assignmentId === assignmentId);
  }

  findByStudent(studentId: string): Submission[] {
    return this.findAll().filter(s => s.studentId === studentId);
  }
}

// Instance unique pour chaque type de stockage
export const storage = {
  users: new UserStorage(),
  classes: new ClassStorage(),
  assignments: new AssignmentStorage(),
  submissions: new SubmissionStorage()
};

// Initialisation des données par défaut
export function initializeDefaultData(): void {
  const users = storage.users.findAll();
  
  // Créer le compte professeur par défaut s'il n'existe pas
  if (!users.some(user => user.role === 'teacher')) {
    storage.users.create({
      id: '1',
      matricule: 'PROF2024',
      password: 'admin123',
      name: 'Professeur Principal',
      role: 'teacher',
      email: 'professeur@ecole.fr'
    });
  }
}