import React, { useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { storage, StoredFile } from '../utils/storage';
import { Upload } from 'lucide-react';

interface FileUploadProps {
  onUpload: (fileId: string) => void;
  assignmentId?: string;
  submissionId?: string;
}

export const FileUpload: React.FC<FileUploadProps> = ({
  onUpload,
  assignmentId,
  submissionId
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isUploading, setIsUploading] = useState(false);

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file || isUploading) return;

    try {
      setIsUploading(true);

      // Create a new FileReader instance
      const reader = new FileReader();
      
      // Convert file reading to Promise
      const readFileAsDataURL = () => {
        return new Promise<string>((resolve, reject) => {
          reader.onload = () => resolve(reader.result as string);
          reader.onerror = () => reject(reader.error);
          reader.readAsDataURL(file);
        });
      };

      // Read the file
      const base64Content = await readFileAsDataURL();
      
      // Create and save the file object
      const storedFile: StoredFile = {
        id: uuidv4(),
        name: file.name,
        content: base64Content.split(',')[1],
        assignmentId,
        submissionId
      };

      // Save the file to storage
      storage.saveFile(storedFile);
      
      // Clear the input
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }

      // Notify parent component
      onUpload(storedFile.id);
    } catch (error) {
      console.error('Error processing file:', error);
      alert('Une erreur est survenue lors du traitement du fichier. Veuillez réessayer.');
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        className="hidden"
        accept=".pdf,.doc,.docx,.txt,.jpg,.jpeg,.png"
        disabled={isUploading}
      />
      <button
        type="button"
        onClick={() => fileInputRef.current?.click()}
        className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
        disabled={isUploading}
      >
        <Upload className="w-4 h-4 mr-2" />
        {isUploading ? 'Chargement...' : 'Ajouter un fichier'}
      </button>
      <p className="mt-1 text-xs text-gray-500">
        Formats acceptés: PDF, DOC, DOCX, TXT, JPG, JPEG, PNG
      </p>
    </div>
  );
};