import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { storage } from '../utils/storage';
import { UserCircle, Users, Lock, GraduationCap, ChevronRight } from 'lucide-react';

export const LoginForm = () => {
  const [selectedRole, setSelectedRole] = useState<'student' | 'teacher' | ''>('');
  const [matricule, setMatricule] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    const users = storage.getUsers();
    const user = users.find(u => u.matricule === matricule);

    if (!user) {
      setError('Matricule ou mot de passe incorrect');
      return;
    }

    if (user.password !== password) {
      setError('Matricule ou mot de passe incorrect');
      return;
    }

    if (selectedRole !== user.role) {
      setError('Le rôle sélectionné ne correspond pas à votre compte');
      return;
    }

    const loginSuccess = login(matricule, password);

    if (loginSuccess) {
      navigate('/dashboard');
    } else {
      setError('Erreur lors de la connexion');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 via-white to-green-50 flex items-center justify-center px-4 sm:px-6 lg:px-8 py-12">
      <div className="max-w-md w-full">
        <div className="relative">
          <div className="absolute inset-0 flex items-center" aria-hidden="true">
            <div className="w-full border-t border-gray-200" />
          </div>
          <div className="relative flex justify-center">
            <div className="px-4 bg-gradient-to-br from-blue-50 via-white to-green-50">
              <GraduationCap className="h-24 w-24 text-green-600 transform hover:scale-110 transition-transform duration-300" />
            </div>
          </div>
        </div>

        <div className="text-center mt-6">
          <h2 className="text-5xl font-extrabold bg-gradient-to-r from-green-600 to-blue-600 bg-clip-text text-transparent">
            CAMPUS UDB
          </h2>
          <p className="mt-3 text-lg text-gray-600 font-medium">
            Votre espace numérique de travail
          </p>
        </div>

        {error && (
          <div className="mt-8 animate-bounce-once">
            <div className="bg-red-50 border-l-4 border-red-400 p-4 rounded-md shadow-sm">
              <div className="flex">
                <div className="flex-shrink-0">
                  <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                  </svg>
                </div>
                <div className="ml-3">
                  <p className="text-sm text-red-700 font-medium">{error}</p>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="mt-12 bg-white/50 backdrop-blur-sm rounded-2xl shadow-xl p-8 space-y-8">
          <div className="grid grid-cols-2 gap-6">
            <button
              type="button"
              onClick={() => setSelectedRole('teacher')}
              className={`relative group flex flex-col items-center justify-center p-6 rounded-xl border-2 transition-all duration-300 ${
                selectedRole === 'teacher'
                  ? 'border-green-500 bg-green-50 shadow-lg transform -translate-y-1'
                  : 'border-gray-200 hover:border-green-200 hover:bg-gray-50 hover:shadow-md'
              }`}
            >
              <div className={`transition-transform duration-300 transform ${
                selectedRole === 'teacher' ? 'scale-110' : 'group-hover:scale-110'
              }`}>
                <Users className={`w-12 h-12 ${selectedRole === 'teacher' ? 'text-green-500' : 'text-gray-400'}`} />
              </div>
              <span className={`mt-4 font-medium text-lg ${selectedRole === 'teacher' ? 'text-green-600' : 'text-gray-600'}`}>
                Professeur
              </span>
              {selectedRole === 'teacher' && (
                <div className="absolute -top-2 -right-2 w-8 h-8 bg-green-500 rounded-full flex items-center justify-center animate-bounce-subtle">
                  <svg className="w-5 h-5 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                </div>
              )}
            </button>

            <button
              type="button"
              onClick={() => setSelectedRole('student')}
              className={`relative group flex flex-col items-center justify-center p-6 rounded-xl border-2 transition-all duration-300 ${
                selectedRole === 'student'
                  ? 'border-green-500 bg-green-50 shadow-lg transform -translate-y-1'
                  : 'border-gray-200 hover:border-green-200 hover:bg-gray-50 hover:shadow-md'
              }`}
            >
              <div className={`transition-transform duration-300 transform ${
                selectedRole === 'student' ? 'scale-110' : 'group-hover:scale-110'
              }`}>
                <UserCircle className={`w-12 h-12 ${selectedRole === 'student' ? 'text-green-500' : 'text-gray-400'}`} />
              </div>
              <span className={`mt-4 font-medium text-lg ${selectedRole === 'student' ? 'text-green-600' : 'text-gray-600'}`}>
                Étudiant
              </span>
              {selectedRole === 'student' && (
                <div className="absolute -top-2 -right-2 w-8 h-8 bg-green-500 rounded-full flex items-center justify-center animate-bounce-subtle">
                  <svg className="w-5 h-5 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                </div>
              )}
            </button>
          </div>

          {selectedRole && (
            <form onSubmit={handleSubmit} className="mt-8 space-y-6 animate-fade-in">
              <div className="space-y-4">
                <div>
                  <label htmlFor="matricule" className="block text-sm font-medium text-gray-700">
                    Matricule
                  </label>
                  <div className="mt-1 relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <UserCircle className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      id="matricule"
                      type="text"
                      required
                      value={matricule}
                      onChange={(e) => setMatricule(e.target.value)}
                      className="appearance-none block w-full pl-10 pr-3 py-3 border border-gray-300 rounded-lg shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500 transition-colors duration-200"
                      placeholder="Entrez votre matricule"
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                    Mot de passe
                  </label>
                  <div className="mt-1 relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <Lock className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      id="password"
                      type="password"
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="appearance-none block w-full pl-10 pr-3 py-3 border border-gray-300 rounded-lg shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500 transition-colors duration-200"
                      placeholder="Entrez votre mot de passe"
                    />
                  </div>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="group relative w-full flex justify-center py-3 px-4 border border-transparent rounded-lg text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition-all duration-200 shadow-lg hover:shadow-xl"
                >
                  <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                    <Lock className="h-5 w-5 text-green-500 group-hover:text-green-400" />
                  </span>
                  <span className="flex items-center">
                    Se connecter
                    <ChevronRight className="ml-2 h-4 w-4 group-hover:translate-x-1 transition-transform duration-200" />
                  </span>
                </button>
              </div>
            </form>
          )}

          {selectedRole === 'student' && (
            <div className="mt-4 text-center bg-blue-50 p-4 rounded-lg border border-blue-100">
              <p className="text-sm text-blue-700">
                <span className="font-medium">Note:</span> Le mot de passe par défaut est{' '}
                <span className="font-bold px-2 py-1 bg-blue-100 rounded">PASSER</span>
              </p>
            </div>
          )}
        </div>

        <div className="mt-8 text-center">
          <p className="text-sm text-gray-500">
            © {new Date().getFullYear()} CAMPUS UDB. Tous droits réservés.
          </p>
        </div>
      </div>
    </div>
  );
};