import React from 'react';
import { storage } from '../../utils/storage';
import { BarChart as BarChartIcon, Users, BookOpen, CheckCircle, Award } from 'lucide-react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LineChart, Line, PieChart, Pie, Cell } from 'recharts';
import { format, subDays } from 'date-fns';
import { useAuth } from '../../contexts/AuthContext';

const COLORS = ['#10B981', '#3B82F6', '#6366F1', '#8B5CF6', '#EC4899'];

export const Statistics = () => {
  const { selectedClass } = useAuth();
  const assignments = storage.getAssignments(selectedClass);
  const submissions = storage.getSubmissions();
  const students = storage.getUsers().filter(u => u.role === 'student' && (!selectedClass || u.class === selectedClass));

  // Basic stats
  const stats = {
    totalStudents: students.length,
    totalAssignments: assignments.length,
    totalSubmissions: submissions.length,
    averageGrade: submissions
      .filter(s => s.grade !== undefined)
      .reduce((acc, s) => acc + (s.grade || 0), 0) / 
      submissions.filter(s => s.grade !== undefined).length || 0,
    submissionRate: (submissions.length / (assignments.length * students.length || 1)) * 100 || 0,
    gradedSubmissions: submissions.filter(s => s.grade !== undefined).length,
  };

  // Grade distribution data
  const gradeRanges = [
    { range: '0-5', min: 0, max: 5 },
    { range: '5-10', min: 5, max: 10 },
    { range: '10-15', min: 10, max: 15 },
    { range: '15-20', min: 15, max: 20 },
  ];

  const gradeDistribution = gradeRanges.map(range => ({
    range: range.range,
    count: submissions.filter(
      s => s.grade !== undefined && s.grade >= range.min && s.grade < range.max
    ).length
  }));

  // Submission timeline data
  const last30Days = [...Array(30)].map((_, i) => {
    const date = subDays(new Date(), i);
    return {
      date: format(date, 'dd/MM'),
      submissions: submissions.filter(s => 
        format(new Date(s.submittedAt), 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd')
      ).length
    };
  }).reverse();

  // Assignment completion status
  const assignmentStatus = [
    { name: 'Soumis et notés', value: stats.gradedSubmissions },
    { name: 'En attente de notation', value: stats.totalSubmissions - stats.gradedSubmissions },
    { name: 'Non soumis', value: (stats.totalAssignments * stats.totalStudents) - stats.totalSubmissions }
  ];

  return (
    <div className="space-y-8">
      {/* Stats Cards */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
        <div className="bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200">
          <div className="flex items-center">
            <Users className="w-12 h-12 text-blue-500" />
            <div className="ml-4">
              <p className="text-sm font-medium text-gray-600">Étudiants inscrits</p>
              <p className="text-2xl font-bold text-gray-900">{stats.totalStudents}</p>
            </div>
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200">
          <div className="flex items-center">
            <BookOpen className="w-12 h-12 text-green-500" />
            <div className="ml-4">
              <p className="text-sm font-medium text-gray-600">Devoirs créés</p>
              <p className="text-2xl font-bold text-gray-900">{stats.totalAssignments}</p>
            </div>
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200">
          <div className="flex items-center">
            <CheckCircle className="w-12 h-12 text-indigo-500" />
            <div className="ml-4">
              <p className="text-sm font-medium text-gray-600">Taux de soumission</p>
              <p className="text-2xl font-bold text-gray-900">{stats.submissionRate.toFixed(1)}%</p>
            </div>
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200">
          <div className="flex items-center">
            <Award className="w-12 h-12 text-yellow-500" />
            <div className="ml-4">
              <p className="text-sm font-medium text-gray-600">Moyenne générale</p>
              <p className="text-2xl font-bold text-gray-900">
                {stats.averageGrade.toFixed(2)}/20
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Charts Grid */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Grade Distribution Chart */}
        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h3 className="text-lg font-medium mb-6">Distribution des notes</h3>
          <div className="h-80">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={gradeDistribution}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="range" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="count" fill="#3B82F6" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Submission Timeline Chart */}
        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h3 className="text-lg font-medium mb-6">Soumissions sur 30 jours</h3>
          <div className="h-80">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={last30Days}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Line type="monotone" dataKey="submissions" stroke="#10B981" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Assignment Status Chart */}
        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h3 className="text-lg font-medium mb-6">État des devoirs</h3>
          <div className="h-80">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={assignmentStatus}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={({ name, value, percent }) => `${name} (${(percent * 100).toFixed(0)}%)`}
                  outerRadius={100}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {assignmentStatus.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Performance Metrics */}
        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h3 className="text-lg font-medium mb-6">Métriques de performance</h3>
          <div className="space-y-4">
            {/* Submission Rate Progress */}
            <div>
              <div className="flex justify-between mb-1">
                <span className="text-sm font-medium text-gray-700">Taux de soumission</span>
                <span className="text-sm font-medium text-gray-700">{stats.submissionRate.toFixed(1)}%</span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2.5">
                <div
                  className="bg-blue-600 h-2.5 rounded-full"
                  style={{ width: `${stats.submissionRate}%` }}
                />
              </div>
            </div>

            {/* Grading Progress */}
            <div>
              <div className="flex justify-between mb-1">
                <span className="text-sm font-medium text-gray-700">Progression de la notation</span>
                <span className="text-sm font-medium text-gray-700">
                  {((stats.gradedSubmissions / stats.totalSubmissions) * 100 || 0).toFixed(1)}%
                </span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2.5">
                <div
                  className="bg-green-600 h-2.5 rounded-full"
                  style={{ width: `${(stats.gradedSubmissions / stats.totalSubmissions) * 100 || 0}%` }}
                />
              </div>
            </div>

            {/* Average Grade Distribution */}
            <div>
              <div className="flex justify-between mb-1">
                <span className="text-sm font-medium text-gray-700">Moyenne générale</span>
                <span className="text-sm font-medium text-gray-700">{stats.averageGrade.toFixed(2)}/20</span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2.5">
                <div
                  className="bg-yellow-600 h-2.5 rounded-full"
                  style={{ width: `${(stats.averageGrade / 20) * 100}%` }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};