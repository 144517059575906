import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { storage } from '../utils/storage';
import { Bell, Download, Paperclip, Check } from 'lucide-react';
import { v4 as uuidv4 } from 'uuid';

export const Notifications = () => {
  const { user } = useAuth();
  const notifications = user ? storage.getNotifications(user.id) : [];
  const unreadCount = notifications.filter(n => !n.read).length;
  const [showNotifications, setShowNotifications] = useState(false);

  const handleMarkAsRead = (notificationId: string, messageId?: string) => {
    storage.markNotificationAsRead(notificationId);

    // If this is a message notification, send a read receipt
    if (messageId && user) {
      const readReceipt = {
        id: uuidv4(),
        userId: user.id,
        title: 'Message lu',
        message: `${user.name} a lu votre message`,
        type: 'info' as const,
        createdAt: new Date().toISOString(),
        read: false,
        isReadReceipt: true,
        originalMessageId: messageId
      };
      storage.saveNotification(readReceipt);
    }
  };

  const handleDownload = (fileId: string) => {
    const file = storage.getFiles().find(f => f.id === fileId);
    if (!file) return;

    const link = document.createElement('a');
    link.href = `data:application/octet-stream;base64,${file.content}`;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="relative">
      <button 
        className="relative p-2"
        onClick={() => setShowNotifications(!showNotifications)}
      >
        <Bell className="w-6 h-6" />
        {unreadCount > 0 && (
          <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">
            {unreadCount}
          </span>
        )}
      </button>

      {showNotifications && notifications.length > 0 && (
        <div className="absolute right-0 mt-2 w-80 bg-white rounded-md shadow-lg overflow-hidden z-50">
          <div className="py-2">
            {notifications.map(notification => (
              <div
                key={notification.id}
                className={`px-4 py-3 hover:bg-gray-50 ${
                  !notification.read ? 'bg-blue-50' : ''
                }`}
                onClick={() => handleMarkAsRead(notification.id, notification.messageId)}
              >
                <div className="flex justify-between items-start">
                  <p className="text-sm font-medium text-gray-900">{notification.title}</p>
                  {notification.read && (
                    <Check className="w-4 h-4 text-green-500" />
                  )}
                </div>
                <p className="text-sm text-gray-500">{notification.message}</p>
                {notification.attachments && notification.attachments.length > 0 && (
                  <div className="mt-2 space-y-1">
                    {notification.attachments.map(fileId => {
                      const file = storage.getFiles().find(f => f.id === fileId);
                      return file && (
                        <button
                          key={file.id}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDownload(file.id);
                          }}
                          className="flex items-center text-sm text-indigo-600 hover:text-indigo-800"
                        >
                          <Paperclip className="w-4 h-4 mr-1" />
                          <span className="mr-2">{file.name}</span>
                          <Download className="w-4 h-4" />
                        </button>
                      );
                    })}
                  </div>
                )}
                <p className="text-xs text-gray-400 mt-1">
                  {new Date(notification.createdAt).toLocaleString()}
                </p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};