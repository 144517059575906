import React, { createContext, useContext, useState, useEffect } from 'react';
import { User } from '../types';
import { storage } from '../utils/storage';

interface AuthContextType {
  user: User | null;
  selectedClass: string | null;
  selectedSubject: string | null;
  login: (matricule: string, password: string) => boolean;
  logout: () => void;
  setSelectedClass: (classId: string) => void;
  setSelectedSubject: (subjectId: string) => void;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [selectedClass, setSelectedClass] = useState<string | null>(null);
  const [selectedSubject, setSelectedSubject] = useState<string | null>(null);

  // Vérifier l'authentification au chargement
  useEffect(() => {
    const checkAuth = () => {
      const currentUser = localStorage.getItem('currentUser');
      const storedClass = localStorage.getItem('selectedClass');
      const storedSubject = localStorage.getItem('selectedSubject');
      
      if (currentUser) {
        const userData = JSON.parse(currentUser);
        // Vérifier si l'utilisateur existe toujours dans le storage
        const userExists = storage.getUsers().some(u => u.id === userData.id);
        if (userExists) {
          setUser(userData);
          if (storedClass) setSelectedClass(storedClass);
          if (storedSubject) setSelectedSubject(storedSubject);
        } else {
          // Nettoyer le storage si l'utilisateur n'existe plus
          localStorage.removeItem('currentUser');
          localStorage.removeItem('selectedClass');
          localStorage.removeItem('selectedSubject');
        }
      }
    };

    checkAuth();

    // Vérifier périodiquement la session
    const interval = setInterval(checkAuth, 5000);
    return () => clearInterval(interval);
  }, []);

  const login = (matricule: string, password: string) => {
    const users = storage.getUsers();
    const foundUser = users.find(
      u => u.matricule === matricule && u.password === password
    );

    if (foundUser) {
      // Stocker l'utilisateur avec un timestamp
      const userSession = {
        ...foundUser,
        lastActivity: new Date().getTime()
      };
      
      setUser(foundUser);
      localStorage.setItem('currentUser', JSON.stringify(userSession));
      return true;
    }
    return false;
  };

  const logout = () => {
    setUser(null);
    setSelectedClass(null);
    setSelectedSubject(null);
    localStorage.removeItem('currentUser');
    localStorage.removeItem('selectedClass');
    localStorage.removeItem('selectedSubject');
  };

  const handleSetSelectedClass = (classId: string) => {
    setSelectedClass(classId);
    localStorage.setItem('selectedClass', classId);
  };

  const handleSetSelectedSubject = (subjectId: string) => {
    setSelectedSubject(subjectId);
    localStorage.setItem('selectedSubject', subjectId);
  };

  // Mettre à jour le timestamp d'activité
  useEffect(() => {
    if (user) {
      const updateActivity = () => {
        const currentUser = localStorage.getItem('currentUser');
        if (currentUser) {
          const userData = JSON.parse(currentUser);
          userData.lastActivity = new Date().getTime();
          localStorage.setItem('currentUser', JSON.stringify(userData));
        }
      };

      // Mettre à jour lors des interactions utilisateur
      window.addEventListener('click', updateActivity);
      window.addEventListener('keypress', updateActivity);
      window.addEventListener('scroll', updateActivity);

      return () => {
        window.removeEventListener('click', updateActivity);
        window.removeEventListener('keypress', updateActivity);
        window.removeEventListener('scroll', updateActivity);
      };
    }
  }, [user]);

  return (
    <AuthContext.Provider 
      value={{ 
        user, 
        selectedClass,
        selectedSubject,
        login, 
        logout, 
        setSelectedClass: handleSetSelectedClass,
        setSelectedSubject: handleSetSelectedSubject
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};