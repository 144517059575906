import { User, Assignment, Submission, Notification, ChatMessage, Group, Class, Message } from '../types';

const USERS_KEY = 'users';
const ASSIGNMENTS_KEY = 'assignments';
const SUBMISSIONS_KEY = 'submissions';
const FILES_KEY = 'files';
const NOTIFICATIONS_KEY = 'notifications';
const CHAT_MESSAGES_KEY = 'chat_messages';
const GROUPS_KEY = 'groups';
const CLASSES_KEY = 'classes';
const MESSAGES_KEY = 'messages';

export interface StoredFile {
  id: string;
  name: string;
  content: string;
  submissionId?: string;
  assignmentId?: string;
  messageId?: string;
}

export const storage = {
  // User methods
  getUsers: (): User[] => {
    const users = localStorage.getItem(USERS_KEY);
    return users ? JSON.parse(users) : [];
  },

  saveUser: (user: User) => {
    const users = storage.getUsers();
    users.push(user);
    localStorage.setItem(USERS_KEY, JSON.stringify(users));
  },

  updateUser: (user: User) => {
    const users = storage.getUsers();
    const index = users.findIndex(u => u.id === user.id);
    if (index !== -1) {
      users[index] = user;
      localStorage.setItem(USERS_KEY, JSON.stringify(users));
    }
  },

  // Class methods
  getClasses: (): Class[] => {
    const classes = localStorage.getItem(CLASSES_KEY);
    return classes ? JSON.parse(classes) : [];
  },

  saveClass: (classData: Class) => {
    const classes = storage.getClasses();
    classes.push(classData);
    localStorage.setItem(CLASSES_KEY, JSON.stringify(classes));
  },

  updateClass: (classData: Class) => {
    const classes = storage.getClasses();
    const index = classes.findIndex(c => c.id === classData.id);
    if (index !== -1) {
      classes[index] = classData;
      localStorage.setItem(CLASSES_KEY, JSON.stringify(classes));
    }
  },

  // Group methods
  getGroups: (): Group[] => {
    const groups = localStorage.getItem(GROUPS_KEY);
    return groups ? JSON.parse(groups) : [];
  },

  saveGroup: (group: Group) => {
    const groups = storage.getGroups();
    groups.push(group);
    localStorage.setItem(GROUPS_KEY, JSON.stringify(groups));
  },

  updateGroup: (group: Group) => {
    const groups = storage.getGroups();
    const index = groups.findIndex(g => g.id === group.id);
    if (index !== -1) {
      groups[index] = group;
      localStorage.setItem(GROUPS_KEY, JSON.stringify(groups));
    }
  },

  // Assignment methods
  getAssignments: (classId?: string): Assignment[] => {
    const assignments = localStorage.getItem(ASSIGNMENTS_KEY);
    const allAssignments = assignments ? JSON.parse(assignments) : [];
    return classId 
      ? allAssignments.filter((a: Assignment) => a.classId === classId)
      : allAssignments;
  },

  saveAssignment: (assignment: Assignment) => {
    const assignments = storage.getAssignments();
    assignments.push(assignment);
    localStorage.setItem(ASSIGNMENTS_KEY, JSON.stringify(assignments));
  },

  updateAssignment: (assignment: Assignment) => {
    const assignments = storage.getAssignments();
    const index = assignments.findIndex(a => a.id === assignment.id);
    if (index !== -1) {
      assignments[index] = assignment;
      localStorage.setItem(ASSIGNMENTS_KEY, JSON.stringify(assignments));
    }
  },

  // Submission methods
  getSubmissions: (): Submission[] => {
    const submissions = localStorage.getItem(SUBMISSIONS_KEY);
    return submissions ? JSON.parse(submissions) : [];
  },

  saveSubmission: (submission: Submission) => {
    const submissions = storage.getSubmissions();
    submissions.push(submission);
    localStorage.setItem(SUBMISSIONS_KEY, JSON.stringify(submissions));
  },

  updateSubmission: (submission: Submission) => {
    const submissions = storage.getSubmissions();
    const index = submissions.findIndex(s => s.id === submission.id);
    if (index !== -1) {
      submissions[index] = submission;
      localStorage.setItem(SUBMISSIONS_KEY, JSON.stringify(submissions));
    }
  },

  // File methods
  getFiles: (): StoredFile[] => {
    const files = localStorage.getItem(FILES_KEY);
    return files ? JSON.parse(files) : [];
  },

  saveFile: (file: StoredFile) => {
    const files = storage.getFiles();
    files.push(file);
    localStorage.setItem(FILES_KEY, JSON.stringify(files));
  },

  // Message methods
  getMessages: (userId: string): Message[] => {
    const messages = localStorage.getItem(MESSAGES_KEY);
    const allMessages = messages ? JSON.parse(messages) : [];
    return allMessages.filter((m: Message) => 
      m.senderId === userId || m.recipientIds.includes(userId)
    );
  },

  saveMessage: (message: Message) => {
    const messages = JSON.parse(localStorage.getItem(MESSAGES_KEY) || '[]');
    messages.push(message);
    localStorage.setItem(MESSAGES_KEY, JSON.stringify(messages));
  },

  updateMessage: (message: Message) => {
    const messages = JSON.parse(localStorage.getItem(MESSAGES_KEY) || '[]');
    const index = messages.findIndex((m: Message) => m.id === message.id);
    if (index !== -1) {
      messages[index] = message;
      localStorage.setItem(MESSAGES_KEY, JSON.stringify(messages));
    }
  },

  // Notification methods
  getNotifications: (userId: string): Notification[] => {
    const notifications = localStorage.getItem(NOTIFICATIONS_KEY);
    const allNotifications = notifications ? JSON.parse(notifications) : [];
    return allNotifications.filter((n: Notification) => 
      typeof n.userId === 'string' 
        ? n.userId === userId
        : n.userId.includes(userId)
    );
  },

  saveNotification: (notification: Notification) => {
    const notifications = JSON.parse(localStorage.getItem(NOTIFICATIONS_KEY) || '[]');
    notifications.push(notification);
    localStorage.setItem(NOTIFICATIONS_KEY, JSON.stringify(notifications));
  },

  markNotificationAsRead: (notificationId: string) => {
    const notifications = JSON.parse(localStorage.getItem(NOTIFICATIONS_KEY) || '[]');
    const updatedNotifications = notifications.map((n: Notification) =>
      n.id === notificationId ? { ...n, read: true } : n
    );
    localStorage.setItem(NOTIFICATIONS_KEY, JSON.stringify(updatedNotifications));
  },

  // Chat methods
  getChatMessages: (groupId: string): ChatMessage[] => {
    const messages = localStorage.getItem(CHAT_MESSAGES_KEY);
    return messages 
      ? JSON.parse(messages).filter((m: ChatMessage) => m.groupId === groupId)
      : [];
  },

  saveChatMessage: (message: ChatMessage) => {
    const messages = JSON.parse(localStorage.getItem(CHAT_MESSAGES_KEY) || '[]');
    messages.push(message);
    localStorage.setItem(CHAT_MESSAGES_KEY, JSON.stringify(messages));
  }
};