import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import { storage } from '../utils/storage';
import { GraduationCap, BookOpen } from 'lucide-react';

export const ClassSelector = () => {
  const { user, selectedClass, setSelectedClass, selectedSubject, setSelectedSubject } = useAuth();
  const classes = storage.getClasses().filter(c => c.teacherId === user?.id);
  const subjects = selectedClass 
    ? classes.find(c => c.id === selectedClass)?.subjects || []
    : [];

  if (!user || user.role !== 'teacher') return null;

  return (
    <div className="flex items-center space-x-4">
      <div className="flex items-center space-x-2">
        <GraduationCap className="w-5 h-5 text-gray-400" />
        <select
          value={selectedClass || ''}
          onChange={(e) => {
            setSelectedClass(e.target.value);
            setSelectedSubject(''); // Reset subject when class changes
          }}
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm"
        >
          <option value="">Sélectionner une classe</option>
          {classes.map(c => (
            <option key={c.id} value={c.id}>
              {c.name}
            </option>
          ))}
        </select>
      </div>

      {selectedClass && (
        <div className="flex items-center space-x-2">
          <BookOpen className="w-5 h-5 text-gray-400" />
          <select
            value={selectedSubject || ''}
            onChange={(e) => setSelectedSubject(e.target.value)}
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm"
          >
            <option value="">Sélectionner une matière</option>
            {subjects.map(subject => (
              <option key={subject.id} value={subject.id}>
                {subject.name}
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
  );
};