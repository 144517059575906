import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { TeacherDashboard } from './TeacherDashboard';
import { StudentDashboard } from './StudentDashboard';
import { ClassSelector } from '../components/ClassSelector';
import { Notifications } from '../components/Notifications';
import { MessagingSystem } from '../components/MessagingSystem';
import { ChangePasswordModal } from '../components/ChangePasswordModal';
import { ThemeToggle } from '../components/ThemeToggle';
import { Settings, LogOut } from 'lucide-react';

export const Dashboard = () => {
  const { user, logout } = useAuth();
  const [showChangePassword, setShowChangePassword] = useState(false);

  if (!user) return null;

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 transition-colors duration-200">
      <nav className="bg-white dark:bg-gray-800 shadow-sm transition-colors duration-200">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center space-x-4">
              <h1 className="text-xl font-semibold text-gray-900 dark:text-white">
                Bienvenue, {user.name}
              </h1>
              <ClassSelector />
            </div>
            <div className="flex items-center space-x-4">
              <ThemeToggle />
              <Notifications />
              <button
                onClick={() => setShowChangePassword(true)}
                className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-500 dark:text-gray-300 hover:text-gray-700 dark:hover:text-white"
              >
                <Settings className="w-4 h-4 mr-2" />
                Paramètres
              </button>
              <button
                onClick={logout}
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 dark:bg-indigo-500 dark:hover:bg-indigo-600 transition-colors duration-200"
              >
                <LogOut className="w-4 h-4 mr-2" />
                Déconnexion
              </button>
            </div>
          </div>
        </div>
      </nav>

      <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        {user.role === 'teacher' ? <TeacherDashboard /> : <StudentDashboard />}
        <MessagingSystem />
      </main>

      {showChangePassword && (
        <ChangePasswordModal onClose={() => setShowChangePassword(false)} />
      )}
    </div>
  );
};