import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { StudentList } from '../components/teacher/StudentList';
import { AssignmentList } from '../components/teacher/AssignmentList';
import { CreateAssignment } from '../components/teacher/CreateAssignment';
import { CreateStudent } from '../components/teacher/CreateStudent';
import { Statistics } from '../components/teacher/Statistics';
import { ClassManagement } from '../components/teacher/ClassManagement';
import { GroupManagement } from '../components/teacher/GroupManagement';
import { Users, BookOpen, Plus, BarChart, GraduationCap, Users2 } from 'lucide-react';
import { storage } from '../utils/storage';

export const TeacherDashboard = () => {
  const [activeTab, setActiveTab] = useState<'overview' | 'students' | 'assignments' | 'classes' | 'groups'>('overview');
  const [showCreateStudent, setShowCreateStudent] = useState(false);
  const [showCreateAssignment, setShowCreateAssignment] = useState(false);
  const { user, selectedClass } = useAuth();

  // Get unread submissions count
  const getUnreadSubmissionsCount = () => {
    if (!user) return 0;
    
    const submissions = storage.getSubmissions();
    const notifications = storage.getNotifications(user.id);
    
    // Filter submissions for the selected class if one is selected
    const relevantSubmissions = selectedClass 
      ? submissions.filter(s => {
          const assignment = storage.getAssignments().find(a => a.id === s.assignmentId);
          return assignment?.classId === selectedClass;
        })
      : submissions;

    // Count submissions that don't have a corresponding read notification
    return relevantSubmissions.filter(submission => {
      const hasReadNotification = notifications.some(
        n => n.messageId === submission.id && n.read
      );
      return !hasReadNotification && submission.status !== 'graded';
    }).length;
  };

  const unreadCount = getUnreadSubmissionsCount();

  return (
    <div className="space-y-6">
      <div className="border-b border-gray-200">
        <nav className="-mb-px flex space-x-8">
          <button
            onClick={() => setActiveTab('overview')}
            className={`${
              activeTab === 'overview'
                ? 'border-indigo-500 text-indigo-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            } flex items-center whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
          >
            <BarChart className="w-5 h-5 mr-2" />
            Vue d'ensemble
          </button>

          <button
            onClick={() => setActiveTab('students')}
            className={`${
              activeTab === 'students'
                ? 'border-indigo-500 text-indigo-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            } flex items-center whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
          >
            <Users className="w-5 h-5 mr-2" />
            Étudiants
          </button>

          <button
            onClick={() => setActiveTab('assignments')}
            className={`${
              activeTab === 'assignments'
                ? 'border-indigo-500 text-indigo-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            } flex items-center whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm relative`}
          >
            <BookOpen className="w-5 h-5 mr-2" />
            Devoirs et TP
            {unreadCount > 0 && (
              <span className="absolute -top-1 -right-1 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full animate-pulse">
                {unreadCount}
              </span>
            )}
          </button>

          <button
            onClick={() => setActiveTab('groups')}
            className={`${
              activeTab === 'groups'
                ? 'border-indigo-500 text-indigo-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            } flex items-center whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
          >
            <Users2 className="w-5 h-5 mr-2" />
            Groupes
          </button>

          <button
            onClick={() => setActiveTab('classes')}
            className={`${
              activeTab === 'classes'
                ? 'border-indigo-500 text-indigo-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            } flex items-center whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
          >
            <GraduationCap className="w-5 h-5 mr-2" />
            Classes et Matières
          </button>
        </nav>
      </div>

      {activeTab !== 'overview' && activeTab !== 'classes' && activeTab !== 'groups' && (
        <div className="flex justify-end">
          <button
            onClick={() => 
              activeTab === 'students' 
                ? setShowCreateStudent(true) 
                : setShowCreateAssignment(true)
            }
            className="flex items-center px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
          >
            <Plus className="w-5 h-5 mr-2" />
            {activeTab === 'students' ? 'Nouvel étudiant' : 'Nouveau devoir/TP'}
          </button>
        </div>
      )}

      {activeTab === 'overview' && <Statistics />}
      {activeTab === 'students' && (
        <>
          <StudentList />
          {showCreateStudent && (
            <CreateStudent onClose={() => setShowCreateStudent(false)} />
          )}
        </>
      )}
      {activeTab === 'assignments' && (
        <>
          <AssignmentList />
          {showCreateAssignment && (
            <CreateAssignment 
              teacherId={user?.id || ''} 
              onClose={() => setShowCreateAssignment(false)} 
            />
          )}
        </>
      )}
      {activeTab === 'classes' && <ClassManagement />}
      {activeTab === 'groups' && <GroupManagement />}
    </div>
  );
};