import React, { useState } from 'react';
import { Assignment } from '../../types';
import { FileUpload } from '../FileUpload';
import { storage } from '../../utils/storage';
import { useAuth } from '../../contexts/AuthContext';
import { v4 as uuidv4 } from 'uuid';
import { Paperclip, Send, X } from 'lucide-react';

interface AssignmentSubmissionProps {
  assignment: Assignment;
  onSubmit: () => void;
  onCancel: () => void;
}

export const AssignmentSubmission: React.FC<AssignmentSubmissionProps> = ({
  assignment,
  onSubmit,
  onCancel,
}) => {
  const [content, setContent] = useState('');
  const [attachments, setAttachments] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { user } = useAuth();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!user || isSubmitting) return;

    try {
      setIsSubmitting(true);

      // Create submission object
      const submission = {
        id: uuidv4(),
        assignmentId: assignment.id,
        studentId: user.id,
        content,
        submittedAt: new Date().toISOString(),
        attachments,
        status: 'pending' as const
      };

      // Save submission to storage
      storage.saveSubmission(submission);

      // Create notification for the teacher
      const notification = {
        id: uuidv4(),
        userId: assignment.teacherId,
        title: `Nouvelle soumission pour ${assignment.title}`,
        message: `L'étudiant ${user.name} a soumis son travail pour ${assignment.title}`,
        type: 'info' as const,
        createdAt: new Date().toISOString(),
        read: false,
        attachments
      };
      storage.saveNotification(notification);

      // Create confirmation notification for the student
      const studentNotification = {
        id: uuidv4(),
        userId: user.id,
        title: 'Soumission réussie',
        message: `Votre travail pour "${assignment.title}" a été soumis avec succès`,
        type: 'success' as const,
        createdAt: new Date().toISOString(),
        read: false
      };
      storage.saveNotification(studentNotification);

      // Clear form and notify parent
      setContent('');
      setAttachments([]);
      onSubmit();
    } catch (error) {
      console.error('Error submitting assignment:', error);
      alert('Une erreur est survenue lors de la soumission. Veuillez réessayer.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleFileUpload = (fileId: string) => {
    try {
      setAttachments(prev => [...prev, fileId]);
    } catch (error) {
      console.error('Error handling file upload:', error);
      alert('Une erreur est survenue lors de l\'ajout du fichier. Veuillez réessayer.');
    }
  };

  const handleRemoveAttachment = (fileId: string) => {
    try {
      setAttachments(prev => prev.filter(id => id !== fileId));
    } catch (error) {
      console.error('Error removing attachment:', error);
      alert('Une erreur est survenue lors de la suppression du fichier. Veuillez réessayer.');
    }
  };

  const files = attachments
    .map(id => storage.getFiles().find(f => f.id === id))
    .filter(Boolean);

  return (
    <div className="bg-white p-6 rounded-lg shadow-lg">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-medium">Soumettre le travail</h3>
        <button 
          onClick={onCancel}
          className="text-gray-400 hover:text-gray-500"
          disabled={isSubmitting}
          type="button"
        >
          <X className="w-5 h-5" />
        </button>
      </div>

      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Votre réponse
          </label>
          <textarea
            value={content}
            onChange={(e) => setContent(e.target.value)}
            rows={6}
            required
            disabled={isSubmitting}
            className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            placeholder="Rédigez votre réponse ici..."
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Pièces jointes
          </label>
          <div className="space-y-2">
            {files.map(file => file && (
              <div key={file.id} className="flex items-center space-x-2 text-sm text-gray-600 bg-gray-50 p-2 rounded">
                <Paperclip className="w-4 h-4" />
                <span>{file.name}</span>
                <button
                  type="button"
                  onClick={() => handleRemoveAttachment(file.id)}
                  className="text-red-500 hover:text-red-700 ml-auto"
                  disabled={isSubmitting}
                >
                  <X className="w-4 h-4" />
                </button>
              </div>
            ))}
          </div>
          <div className="mt-2">
            <FileUpload
              onUpload={handleFileUpload}
              assignmentId={assignment.id}
            />
          </div>
        </div>

        <div className="flex justify-end space-x-3 pt-4">
          <button
            type="button"
            onClick={onCancel}
            className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
            disabled={isSubmitting}
          >
            Annuler
          </button>
          <button
            type="submit"
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={isSubmitting}
          >
            <Send className="w-4 h-4 mr-2" />
            {isSubmitting ? 'Envoi en cours...' : 'Soumettre'}
          </button>
        </div>
      </form>
    </div>
  );
};